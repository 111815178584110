.main-wrapper {
  margin-left: 269px;
  width: calc(100vw - 270px);
  margin-top: 100px;
}
body {
  background-color: #f5f6fa;
}
.mr-3 {
  margin-right: 10px;
}
