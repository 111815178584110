/* Main container */
.create-delivery-container {
  position: relative;
  padding: 1rem 1.2rem;
  margin-top: 60px;
  height: calc(100vh - 60px);
}

/* Header container for Create button and Legends */
.create-delivery-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Create Care Home button */
.create-delivery-invite-btn {
  background-color: #735dff;
  color: white;
  border: none;
  padding: 8px 16px;
  font-family: "Figtree-SemiBold";
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease;
}

.create-delivery-invite-btn:hover {
  background-color: #5933cc;
}

.create-delivery-invite-icon {
  margin-right: 5px;
}

/* Legends container */
.create-delivery-legends {
  display: flex;
  gap: 1rem;
}

.create-delivery-legend-item {
  display: flex;
  align-items: center;
  font-family: "Figtree-Regular";
  font-size: 0.9rem;
}

.create-delivery-legend-icon {
  font-size: 1.2rem;
  margin-right: 5px;
}

.create-delivery-legend-text {
  margin-left: 5px;
}

.create-delivery-legend-create-deliverys {
  color: #0cc763;
}
.create-delivery-legend-view {
  color: #ffb300;
}

.create-delivery-legend-update {
  color: #0c9cfc;
}

.create-delivery-legend-delete {
  color: #ff5a29;
}

.create-delivery-legend-reassign-shelf {
  color: #ffb300;
}

/* Table container */
.create-delivery-table-container {
  margin-top: 20px;
  overflow: auto;
}

.create-delivery-table-container table {
  width: 100%;
  font-family: "Figtree-Regular";
  border: 1px solid #ddd;
}

.create-delivery-table-container th {
  background-color: #f9f9f9;
  font-family: "Figtree-SemiBold";
  font-size: 0.9rem;
  color: #333;
  text-align: left;
  padding: 10px;
}

.create-delivery-table-container td {
  padding: 10px;
  font-family: "Figtree-Regular";
  font-size: 0.9rem;
  color: #333;
  border-bottom: 1px solid #f0f0f0;
}

/* Action icons */
.create-delivery-action-icon {
  font-size: 25px;
  cursor: pointer;
  transition: color 0.3s ease, background-color 0.3s ease;
  padding: 5px;
  border-radius: 50%;
  margin-right: 5px;
}

.create-delivery-view-create-deliverys-icon {
  color: #0cc763;
}

.create-delivery-view-icon {
  color: #ffb300;
}

.create-delivery-edit-icon {
  color: #0c9cfc;
}

.create-delivery-delete-icon {
  color: #ff5a29;
}

.create-delivery-view-create-deliverys-icon:hover {
  background-color: #e6ffe6;
}

.create-delivery-view-icon:hover {
  background-color: #e6f5ff;
}

.create-delivery-edit-icon:hover {
  background-color: #fff6d2;
}

.create-delivery-delete-icon:hover {
  background-color: #ffe4e1;
}

.create-delivery-reassign-button {
  background: none;
  border: none;
  color: #3498db;
  padding: 5px;
  margin-left: 5px;
  transition: color 0.3s ease;
}

.create-delivery-reassign-button:hover {
  color: #2980b9;
}
/* Pagination */
.create-delivery-pagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.create-delivery-pagination-btn {
  background-color: #735dff26;
  color: #735dff;
  border: none;
  padding: 5px 15px;
  cursor: pointer;
  margin: 0 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
  font-family: "Figtree-SemiBold";
}

.create-delivery-pagination-btn:hover {
  background-color: #735dff;
  color: #fff;
}

.create-delivery-pagination-btn:disabled {
  background-color: #e0e0e0;
  color: #999;
}

.create-delivery-page-number {
  font-size: 0.9rem;
  font-family: "Figtree-Regular";
  color: #666;
}

/* Modal specific styles */
.create-delivery-modal .modal-header {
  background-color: #f9f9f9;
  border-bottom: 1px solid #ddd;
}

.create-delivery-modal .modal-title {
  font-family: "Figtree-SemiBold";
}

.create-delivery-modal .modal-body {
  font-family: "Figtree-Regular";
}

.create-delivery-modal .modal-footer button {
  font-family: "Figtree-SemiBold";
}

.create-delivery-search-input-box {
  width: 100%;
  margin-bottom: 10px;
}
.create-delivery-search-input {
  width: 100%;
}
.create-delivery-legend-collected-by {
  color: #5933cc;
}
.create-delivery-legend-patients-address {
  color: #ffb300;
}
.create-delivery-legend-carehome-address {
  color: #0cc763;
}
.create-delivery-accordion-header {
  display: flex;
  align-items: center;
}

.create-delivery-accordion-patient-name {
  font-size: 1rem;
  font-family: "Figtree-SemiBold";
  margin: 0 0 2px 0;
}

.create-delivery-accordion-patient-nhs {
  font-family: "Figtree-Italic";
  font-size: 0.8rem;
  color: #666;
  margin: 0;
}

.create-delivery-accordion-patient-address {
  font-family: "Figtree-Regular";
  font-size: 0.9rem;
  color: #666;
  margin: 0 0 0 10px;
}

.create-delivery-create-patient-address {
  display: flex;
  align-items: center;
}

.create-delivery-create-patient-carehome-name {
  font-family: "Figtree-Regular";
  font-size: 0.9rem;
  color: #666;
  margin: 0 0 0 10px;
}

.create-delivery-collect-button-container {
  display: flex;
  justify-content: space-around;
}

.create-delivery-collect-button {
  background-color: #735dff;
  color: white;
  border: none;
  padding: 2px 10px;
  font-family: "Figtree-SemiBold";
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease;
}

.create-delivery-collect-button:hover {
  background-color: #5933cc;
}

.create-delivery-reassign-button {
  background-color: #ffb300;
  color: white;
  border: none;
  padding: 2px 10px;
  font-family: "Figtree-SemiBold";
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  align-items: center;
  margin-left: 5px;
  transition: background-color 0.3s ease;
}

.create-delivery-reassign-button:hover {
  background-color: #ffb300;
}

.create-delivery-accordion-container {
  height: calc(100vh - 300px);
  overflow: auto;
}

.create-delivery-items-per-page-selector {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 10px 0;
}

.create-delivery-form-select {
  border: 1px solid #5933cc !important;
  background-color: #f8f9fa !important;
  color: #5933cc !important;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  outline: none;
  border-radius: 4px;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  height: fit-content;
  padding: 2px 5px;
  line-height: normal;
  height: auto;
}

.create-delivery-items-per-page-option {
  font-size: 0.9rem;
  padding: 5px 10px;
  margin: 0 5px;
}

/* Offcanvas styles */
.create-delivery-offcanvas {
  width: 75% !important;
}

.create-delivery-offcanvas-header {
  background-color: #f9f9f9;
  border-bottom: 1px solid #ddd;
  padding: 1rem 1.5rem;
}

.create-delivery-offcanvas-header .btn-close {
  margin: 0;
}

.create-delivery-offcanvas-body {
  padding: 1.5rem;
}

.create-delivery-offcanvas-form {
  width: 100%;
}

.create-delivery-offcanvas-footer {
  padding: 1rem 1.5rem;
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  border-top: 1px solid #ddd;
  background-color: #f9f9f9;
}

.create-delivery-offcanvas-title {
  font-family: "Figtree-SemiBold";
  color: #333;
  margin-bottom: 1.5rem;
  padding: 0 0.75rem;
}

/* Form styles within offcanvas */
.create-delivery-offcanvas .form-group {
  margin-bottom: 1.5rem;
}

.create-delivery-offcanvas .form-label {
  font-family: "Figtree-SemiBold";
  margin-bottom: 0.5rem;
}

.create-delivery-offcanvas .form-control {
  font-family: "Figtree-Regular";
  border-radius: 5px;
  border: 1px solid #ddd;
  padding: 0.5rem;
}

/* Detail view styles */
.create-delivery-details-section {
  margin-bottom: 2rem;
}

.create-delivery-detail-item {
  margin-bottom: 1rem;
  font-family: "Figtree-Regular";
  padding: 0.5rem;
  border-bottom: 1px solid #f0f0f0;
}

.create-delivery-detail-item strong {
  font-family: "Figtree-SemiBold";
  margin-right: 0.5rem;
  color: #666;
}

/* Delete Modal specific styles */
.create-delivery-delete-modal .modal-content {
  border-radius: 8px;
}

.create-delivery-delete-modal .modal-header {
  background-color: #f8d7da;
  border-bottom: 1px solid #f5c6cb;
}

.create-delivery-delete-modal .modal-title {
  color: #721c24;
  font-family: "Figtree-SemiBold";
}

.create-delivery-delete-modal .modal-body {
  padding: 1.5rem;
  font-family: "Figtree-Regular";
}

/* Confirmation Modal specific styles */
.create-delivery-confirmation-modal .modal-content {
  border-radius: 8px;
}

.create-delivery-confirmation-modal .modal-header {
  background-color: #f8d7da;
  border-bottom: 1px solid #f5c6cb;
}

.create-delivery-confirmation-modal .modal-title {
  color: #721c24;
  font-family: "Figtree-SemiBold";
}

.create-delivery-confirmation-modal .modal-body {
  padding: 1.5rem;
  font-family: "Figtree-Regular";
}

/* Button styles */
.create-delivery-confirmation-modal .btn-primary {
  background-color: #735dff;
  border-color: #735dff;
}

.create-delivery-confirmation-modal .btn-primary:hover {
  background-color: #5933cc;
  border-color: #5933cc;
}
