.plan-section {
  margin: 100px 0;
}
.plan-toggle-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.plan-container {
  margin-top: 20px;
}
.plan-card {
  width: 500px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}
.plan-title {
  font-size: 2rem;
}
.plan-description {
  font-size: 1.2rem;
}
.plan-price {
  font-size: 1.3rem;
  font-weight: 600;
}
.plan-text {
  font-size: 1rem;
}
.plan-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
}
.features-list {
  list-style: none;
  padding-left: 0;
}

.features-list li {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.feature-icon {
  color: #28a745; /* Green color for the check icon */
  margin-right: 10px;
  font-size: 18px;
  min-width: 18px;
}

.current-plan-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.current-plan-text {
  margin-right: 20px;
}

.cancel-subscription-button {
  margin-left: 20px;
}

.current-plan-dates-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.current-date-plan-text {
  font-size: 1rem;
  color: #6c757d;
  font-weight: 500;
  margin-bottom: 10px;
  margin-top: 10px;
  display: flex;
  align-items: center;

  justify-content: space-between;

  /* Add this line */
  flex-wrap: wrap;
}

.current-date-text {
  font-size: 1rem;
  color: #6c757d;
  font-weight: 500;
}
@media screen and (max-width: 1000px) {
  .plan-card {
    width: 400px;
  }
}
@media screen and (max-width: 700px) {
  .plan-card {
    width: 350px;
  }
}
