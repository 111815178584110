.mt-100 {
  margin-top: 100px;
}
.mt-150 {
  margin-top: 150px;
}
.mt-200 {
  margin-top: 200px;
}
.edit-btn {
  border: 1px solid #07bfb5;
  border-radius: 15px;
  padding: 10px;
}
.title-name-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.profile-details {
  margin-top: 10px;
}
.profile-details p {
  font-size: 14px;
}
.profile-details span {
  font-size: 14px;
  color: gray;
  font-style: italic;
}
