.m-10 {
  margin: 10px 0;
}

.patient-search-container {
  position: relative;
}
.request-patient-search-input-box {
  width: 100%;
  margin-bottom: 10px;
}
.request-patient-search-input {
  width: 100%;
}

.request-rx-patient-select-btn {
  background-color: #735dff26;
  color: #735dff;
  border: none;
  padding: 2px 10px;
  font-family: "Figtree-SemiBold";
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease;
}

.request-rx-patient-select-btn:hover {
  background-color: #735dff;
  color: #fff;
}

.request-rx-pagination-btn {
  background-color: #735dff26;
  color: #735dff;
  border: none;
  padding: 5px 15px;
  cursor: pointer;
  margin: 0 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
  font-family: "Figtree-SemiBold";
}

.request-rx-pagination-btn:hover {
  background-color: #735dff;
  color: #fff;
}

.request-rx-pagination-btn:disabled {
  background-color: #e0e0e0;
  color: #999;
}
/* Table container */
.request-patient-table-container {
  margin-top: 20px;
  overflow: auto;
}

.request-patient-table-container table {
  width: 100%;
  font-family: "Figtree-Regular";
  border: 1px solid #ddd;
}

.request-patient-table-container th {
  background-color: #f9f9f9;
  font-family: "Figtree-SemiBold";
  font-size: 0.9rem;
  color: #333;
  text-align: left;
  padding: 10px;
}

.request-patient-table-container td {
  padding: 10px;
  font-family: "Figtree-Regular";
  font-size: 0.9rem;
  color: #333;
  border-bottom: 1px solid #f0f0f0;
}
