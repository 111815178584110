.page-number-box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 10px;
}

.page-number-box .pagination {
  margin-bottom: 0;
}

.page-number-box .pagination li a {
  font-size: 0.85rem;
}

.page-number-box .form-select {
  font-size: 0.85rem;
  padding: 0.25rem 0.5rem;
  max-width: 100px;
}
.filter-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.search-filter-input {
  max-width: 300px;
}
.exporting-buttons {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.exporting-buttons button {
  margin: 5px 0;
}
.measured-modal-body {
  max-height: 500px;
  overflow: auto;
}
