.sort-icons {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  margin-left: 8px;
}
.sort-icons .active {
  color: #007bff; /* Highlighted color for active sort */
}
.notification-header th {
}

.mark-read-btn {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
}

.mark-read-btn:hover {
  background-color: #218838;
}
