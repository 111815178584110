.signup-container {
  width: 400px;
  margin: 0 auto;
  padding: 20px;
}

.signup-box {
  height: calc(100vh - 100px);
  overflow: auto;
}

.signup-container h2 {
  color: #07bfb5;
  margin-bottom: 10px;
}

.form-group {
  margin-bottom: 10px;
}

.signup-button {
  display: block;
  width: 100%;
  padding: 10px;
  background: #07bfb5;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  margin: 20px 0;
}

.signup-button:hover {
  border: 1px solid #07bfb5;
  color: #07bfb5 !important;
  background: none !important;
}

.signup-footer {
  display: flex;
  justify-content: space-between;
}
.signup-footer a {
  color: #07bfb5;
  text-decoration: none;
  font-size: 0.9rem;
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.signup-footer a::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 1px;
  background-color: currentColor; /* Uses the text color */
  transition: width 0.3s ease-out;
}

.signup-footer a:hover::after {
  width: 100%;
}
