.mt-100 {
  margin-top: 100px;
}
.page-number-box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 10px;
}

.page-number-box .pagination {
  margin-bottom: 0;
}

.page-number-box .pagination li a {
  font-size: 0.85rem;
}

.page-number-box .form-select {
  font-size: 0.85rem;
  padding: 0.25rem 0.5rem;
  max-width: 100px;
}
.filter-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.search-filter-input {
  max-width: 300px;
}
.exporting-buttons {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.exporting-buttons button {
  margin: 5px 0;
}
.measured-modal-body {
  max-height: 500px;
  overflow: auto;
}
.mds-reports-table-title-box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
}
.mds-reports-span {
  font-size: 1rem;
  font-weight: 700;
  font-style: italic;
  color: #735dff;
}
.mds-table-pagination-nav-box {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 20px 0;
}
/* Style for links within the page items */
.custom-table-pagination {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}
.custom-table-pagination li {
  margin: 0px;
}
.custom-table-pagination li button {
  border: 1px solid #ccc;
  padding: 5px 10px;
  background-color: #f8f9fa;
  color: #735dff;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

/* Hover effect */
.custom-table-pagination li button:hover {
  background-color: #735dff26;
  color: #ffffff;
}

.custom-table-pagination li.active button {
  background-color: #735dff26;
  color: #735dff;
  cursor: default;
  border-color: #735dff;
}
/* Base style for the container around the select dropdown */
.items-per-page-selector {
  display: flex; /* Use flex layout to align the dropdown nicely */
  justify-content: flex-start; /* Align dropdown to the start */
}

/* Style for the select dropdown itself */
.items-per-page-selector select {
  border: 1px solid #735dff !important;
  background-color: #f8f9fa !important;
  color: #735dff !important;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  outline: none;
  border-radius: 4px;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  height: fit-content;
  padding: 7px 7px;
  line-height: normal;
  height: auto;
}

/* Hover effect for the select dropdown */
.items-per-page-selector select:hover {
  background-color: #735dff !important; /* Hover background color to match buttons */
  color: #ffffff !important; /* Text color change on hover */
}

/* Focus style for select (when clicked or tabbed into) */
.items-per-page-selector select:focus {
  border-color: #735dff; /* Highlight color to maintain consistency */
  box-shadow: 0 0 0 2px rgba(7, 191, 181, 0.25); /* Soft glow for focus, matching your theme color */
}
.sort-button {
  color: #000;
  text-decoration: none;
}
.sort-button:hover {
  color: #735dff;
}
.sort-button:focus {
  color: #735dff;
}
.accordion-patient-name > button:focus {
  color: #000;
}
/* .accordion-button:not(.collapsed) {
  color: #ffffff;
  background-color: #735dff26;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
} */
