/* Overlay.css */
.overlay {
  position: absolute;
  top: -40px;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}

.overlay-content {
  text-align: center;
}
.overlay-content h2 {
  color: #000;
}
