.page-number-box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 10px;
}
.mt-80 {
  margin-top: 80px;
}
.page-number-box .pagination {
  margin-bottom: 0;
}

.page-number-box .pagination li a {
  font-size: 0.85rem;
}

.page-number-box .form-select {
  font-size: 0.85rem;
  padding: 0.25rem 0.5rem;
  max-width: 200px;
}
.filter-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.search-filter-input {
  max-width: 300px;
}
.mdsStatusBtn {
  justify-content: space-between;
}
.mdsStatusBtn button {
  border: none;
}
.status-timeline .status-timeline-item {
  position: relative;
  margin-bottom: 20px;
  padding-left: 20px;
}

.status-timeline .status-timeline-item::before {
  content: "";
  position: absolute;
  left: 7px;
  top: 15px;
  bottom: -15px;
  width: 2px;
  background-color: #ccc; /* Light gray for the line */
}

.status-timeline .status-timeline-dot {
  position: absolute;
  left: 0;
  top: 0;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: #ccc; /* Default gray color */
  z-index: 1;
}

.status-timeline .status-timeline-item:first-child .status-timeline-dot {
  background-color: #007bff; /* Blue color for the latest dot */
}

.status-timeline .status-timeline-item:last-child::before {
  content: none; /* Removes the line going below the last item */
}

.status-timeline .status-timeline-content {
  margin-left: 20px;
}
