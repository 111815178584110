/* notification.css */
.notification-menu {
  position: absolute;
  top: 40px;
  right: 0;
  background: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 300px;
  overflow: auto;
  max-height: 600px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}
.notification-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}
.notification-header-text {
  font-size: 1rem;
  font-family: "Figtree-SemiBold";
  margin: 0;
}
.notification-header-count-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ff5a2926;
  border-radius: 5px;
}
.notification-header-count {
  font-size: 0.75rem;
  margin: 0;
  padding: 2px 10px;
  color: #ff5a29;
  font-family: "Figtree-Regular";
}
.notification-item {
  padding: 10px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.notification-item > p:hover {
  color: #07bfb5;
}
.notification-item > p {
  padding: 0;
  margin: 0;
}
.header-notification-btn {
  padding: 5px 5px;
  border: none;
  margin-right: 10px;
  position: relative;
  background: transparent;
}
.header-notification-btn > svg {
  display: flex;
  align-items: center;
  padding: 7px 2px 5px 2px;
  color: #61748f;
  width: 34px;
  height: 34px;
  font-size: 1rem;
  /* Apply the wiggle animation to the bell icon */
  animation: wiggle 3.5s linear infinite; /* Total duration of 3.5s (0.5s wiggle + 3s pause) */
}
.header-btn .btn-svg {
  vertical-align: middle;
}

.notification-dot {
  position: absolute;
  top: 15%;
  left: 20px;
  width: 5px;
  height: 5px;
  background-color: red;
  border-radius: 50%;
  animation: pulse 1.5s infinite;
}

.notification-text {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-height: 3.6rem; /* roughly two lines */
  font-size: 14px;
  max-width: 200px;
  margin: 0;
  font-family: "Figtree-Regular";
}
.notification-empty {
  padding: 20px;
  text-align: center;
  font-size: 14px;
  font-family: "Figtree-Regular";
}
.notification-time {
  font-size: 0.75rem;
  color: #888;
  margin-top: 5px;
  font-family: "Figtree-Italic";
}
.notification-priority-icon {
  font-size: 20px;
  margin-right: 5px;
}

.notification-empty {
  padding: 20px;
  text-align: center;
}
.tick-btn {
  border: none;
  background: none !important;
  cursor: pointer; /* Indicates that the button is clickable */
  display: flex; /* Aligns the icon within the button properly */
  align-items: center; /* Centers the icon vertically */
  justify-content: center; /* Centers the icon horizontally */
}

.notification-tick-icon {
  font-size: 12px; /* Adjust icon size as needed */
  color: #b0b0b0; /* Lighter color for the icon */
  transition: color 0.3s ease; /* Smooth transition for color change */
}

.tick-btn:hover .notification-tick-icon {
  color: #463194; /* Darker color when hovered */
}

.view-all-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  width: 100%;
  text-align: center;
  padding: 10px;
  background: #f5f5f5;
  border-top: 1px solid #eee;
  position: relative; /* Needed for absolute positioning of pseudo-element */
  overflow: hidden; /* Ensures the pseudo-element does not extend outside the button */
}

.view-all-btn::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%; /* Start with no underline visible */
  height: 2px;
  background-color: #463194; /* Underline color */
  transition: width 0.3s ease; /* Animate the width change */
}

.view-all-btn:hover::after {
  width: 100%; /* Full width on hover */
}

.view-all-text {
  font-size: 14px;
  margin: 0;
  padding: 0;
  font-family: "Figtree-Regular";
  color: #463194;
  transition: color 0.3s ease; /* Smooth color transition on hover */
}

.view-all-icon {
  font-size: 20px;
  margin-left: 5px;
  color: #463194;
  transition: transform 0.3s ease; /* Optional: animate the icon's position */
}

.view-all-btn:hover .view-all-text {
  color: #463194; /* Change text color on hover, optional */
}

.view-all-btn:hover .view-all-icon {
  transform: translateX(
    5px
  ); /* Move the icon to the right on hover, optional */
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.5);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes wiggle {
  0%,
  14.3% {
    /* 0.5s out of 3.5s */
    transform: rotate(0deg);
  }
  2%,
  12.3% {
    transform: rotate(-10deg); /* Tilt left */
  }
  4%,
  10.3% {
    transform: rotate(10deg); /* Tilt right */
  }
  6%,
  8.3% {
    transform: rotate(-10deg); /* Tilt left */
  }
  7.15%,
  100% {
    /* Pause after the quick wiggle */
    transform: rotate(0deg);
  }
}
