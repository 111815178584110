.dashboard-main-box > div {
  margin: 20px 0 0 0;
}
.dashboard-welcome-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px;
  background-color: #f9f9f9;
}
.dashboard-welcome-title {
  font-family: "Figtree-SemiBold";
  font-size: 1.25rem;
  color: #333;
  margin-bottom: 10px;
}
.dashboard-welcome-text {
  font-family: "Figtree-Regular";
  font-size: 1rem;
  color: #333;
}
.dashboard-highlight-text {
  font-family: "Figtree-Bold";
  font-size: 1rem;
  color: #ff5a29;
}
