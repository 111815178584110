.route-graph-container {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 15px;
}

.route-dashboard-title {
  font-family: "Figtree-SemiBold";
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 15px;
}
