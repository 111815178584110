.main-row {
  align-items: center;
  height: 100vh;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.row-centered {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.corn-blue {
  background: #6495eda8;
}
.cadet-blue {
  background: #5f9ea0b5;
}
