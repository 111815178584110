.line-graph-container {
  padding: 15px;
  border-radius: 15px;
}

.linegraph-title-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.delivery-dashboard-title {
  font-family: "Figtree-SemiBold";
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 15px;
}

.dashboard-linegraph-title {
  color: #333;
  margin-right: 10px;
  font-family: "Figtree-SemiBold";
  font-size: 1rem;
}

.linegraph-legend {
  flex: 1;
  text-align: left;
}

.lineGraphButtonBox button {
  background-color: #735dff26;
  color: #735dff;
  border: none;
  padding: 5px 15px;
  margin: 0;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.lineGraphButtonBox button:not(:last-child) {
  border-right: 1px solid #735dff;
}

.lineGraphButtonBox button:first-child {
  border-radius: 5px 0 0 5px;
}

.lineGraphButtonBox button:last-child {
  border-radius: 0 5px 5px 0;
}

.lineGraphButtonBox button:hover {
  background-color: #735dff;
  color: #fff;
}

.lineGraphButtonBox .active-months {
  background-color: #735dff;
  color: white;
  border: none;
}
