.navbar-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-right: 40px;
}
.profile-container,
.notification-container {
  position: relative; /* Needed for absolute positioning of dropdowns */
}
.navbar-custom {
  background: #fff;
}
