/* Offcanvas styles */
.appointment-offcanvas {
  width: 75% !important;
}

.appointment-offcanvas-header {
  background-color: #f9f9f9;
  border-bottom: 1px solid #ddd;
  padding: 1rem 1.5rem;
}

.appointment-offcanvas-header .btn-close {
  margin: 0;
}

.appointment-offcanvas-body {
  padding: 1.5rem;
}

.appointment-offcanvas-form {
  width: 100%;
}

.appointment-offcanvas-footer {
  padding: 1rem 1.5rem;
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  border-top: 1px solid #ddd;
  background-color: #f9f9f9;
}

.appointment-offcanvas-title {
  font-family: "Figtree-SemiBold";
  color: #333;
  margin-bottom: 1.5rem;
  padding: 0 0.75rem;
}

/* Form styles within offcanvas */
.appointment-offcanvas .form-group {
  margin-bottom: 1.5rem;
}

.appointment-offcanvas .form-label {
  font-family: "Figtree-SemiBold";
  margin-bottom: 0.5rem;
}

.appointment-offcanvas .form-control {
  font-family: "Figtree-Regular";
  border-radius: 5px;
  border: 1px solid #ddd;
  padding: 0.5rem;
}

/* Detail view styles */
.appointment-details-section {
  margin-bottom: 2rem;
}

.appointment-detail-item {
  margin-bottom: 1rem;
  font-family: "Figtree-Regular";
  padding: 0.5rem;
  border-bottom: 1px solid #f0f0f0;
}

.appointment-detail-item strong {
  font-family: "Figtree-SemiBold";
  margin-right: 0.5rem;
  color: #666;
}

/* Delete Modal specific styles */
.appointment-delete-modal .modal-content {
  border-radius: 8px;
}

.appointment-delete-modal .modal-header {
  background-color: #f8d7da;
  border-bottom: 1px solid #f5c6cb;
}

.appointment-delete-modal .modal-title {
  color: #721c24;
  font-family: "Figtree-SemiBold";
}

.appointment-delete-modal .modal-body {
  padding: 1.5rem;
  font-family: "Figtree-Regular";
}

/* Confirmation Modal specific styles */
.appointment-confirmation-modal .modal-content {
  border-radius: 8px;
}

.appointment-confirmation-modal .modal-header {
  background-color: #f8d7da;
  border-bottom: 1px solid #f5c6cb;
}

.appointment-confirmation-modal .modal-title {
  color: #721c24;
  font-family: "Figtree-SemiBold";
}

.appointment-confirmation-modal .modal-body {
  padding: 1.5rem;
  font-family: "Figtree-Regular";
}

/* Button styles */
.appointment-confirmation-modal .btn-primary {
  background-color: #735dff;
  border-color: #735dff;
}

.appointment-confirmation-modal .btn-primary:hover {
  background-color: #5933cc;
  border-color: #5933cc;
}

/* @media smaller than 1000px */
@media (max-width: 1000px) {
  .appointment-header {
    flex-direction: column;
    justify-content: flex-start;
    gap: 5px;
  }
}
/* media smaller than 750px */
@media (max-width: 750px) {
  .appointment-filter-box {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 5px;
  }
}
/* media smaller than 500px */
@media (max-width: 500px) {
  .appointment-date-filter-box {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 5px;
  }
}
