.collection-container {
  margin-left: 500px;
}
.mt-60 {
  margin-top: 60px;
}
.title-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
