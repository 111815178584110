.rbc-header {
  background: #735dff;
  color: #fff;
  padding: 5px;
  font-size: 12px;
}
.rbc-event {
  background-color: #735dffc2;
  display: flex;
  align-items: center;
}
.rbc-day-slot .rbc-event,
.rbc-day-slot .rbc-background-event {
  justify-content: center;
}
.rbc-event-content {
  display: flex;
  align-items: center;
  background-color: #735dff26;
  padding: 2px;
  color: #fff;
  font-size: 0.5rem;
}
.calendar-booking-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 10px;
}
.calendar-booking-container > button {
  margin-top: 10px;
  background-color: #735dff;
}
.event-modal,
.event-date {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.event-modal .event-with {
  color: black;
  font-size: 16px;
  font-weight: bold;
}
.event-modal .event-type {
  color: gray;
  font-size: 16;
  font-weight: 500;
}

.event-date > p {
  color: gray;
  font-size: 16;
  font-weight: 500;
}
.event-date > span {
  font-size: 16px;
  font-weight: bold;
  color: black;
}

/* Offcanvas styles */
.mds-offcanvas {
  width: 75% !important;
}

.mds-offcanvas-header {
  background-color: #f9f9f9;
  border-bottom: 1px solid #ddd;
  padding: 1rem 1.5rem;
}

.mds-offcanvas-header .btn-close {
  margin: 0;
}

.mds-offcanvas-body {
  padding: 1.5rem;
}

.mds-offcanvas-form {
  width: 100%;
}

.mds-offcanvas-footer {
  padding: 1rem 1.5rem;
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  border-top: 1px solid #ddd;
  background-color: #f9f9f9;
}

.mds-offcanvas-title {
  font-family: "Figtree-SemiBold";
  color: #333;
  margin-bottom: 1.5rem;
  padding: 0 0.75rem;
}

/* Form styles within offcanvas */
.mds-offcanvas .form-group {
  margin-bottom: 1.5rem;
}

.mds-offcanvas .form-label {
  font-family: "Figtree-SemiBold";
  margin-bottom: 0.5rem;
}

.mds-offcanvas .form-control {
  font-family: "Figtree-Regular";
  border-radius: 5px;
  border: 1px solid #ddd;
  padding: 0.5rem;
}

/* Detail view styles */
.mds-details-section {
  margin-bottom: 2rem;
}

.mds-detail-item {
  margin-bottom: 1rem;
  font-family: "Figtree-Regular";
  padding: 0.5rem;
  border-bottom: 1px solid #f0f0f0;
}

.mds-detail-item strong {
  font-family: "Figtree-SemiBold";
  margin-right: 0.5rem;
  color: #666;
}

/* Delete Modal specific styles */
.mds-delete-modal .modal-content {
  border-radius: 8px;
}

.mds-delete-modal .modal-header {
  background-color: #f8d7da;
  border-bottom: 1px solid #f5c6cb;
}

.mds-delete-modal .modal-title {
  color: #721c24;
  font-family: "Figtree-SemiBold";
}

.mds-delete-modal .modal-body {
  padding: 1.5rem;
  font-family: "Figtree-Regular";
}

/* Button styles */
.mds-offcanvas .btn-primary {
  background-color: #735dff;
  border-color: #735dff;
}

.mds-offcanvas .btn-primary:hover {
  background-color: #5933cc;
  border-color: #5933cc;
}
