.dashboard-calendar-container {
  padding: 15px;
  background-color: #f9f9f9;
}

.calendar-title-box {
  margin-bottom: 15px;
}

.calendar-dashboard-title {
  font-family: "Figtree-SemiBold";
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 15px;
}

.calendar-dashboard-table-body {
  background-color: #fff;
  border-radius: 5px;
  padding: 10px;
}

.calendar-dashboard-table-body td {
  font-family: "Figtree-Regular";
  font-size: 0.9rem;
  color: #333;
  padding: 10px;
}

.event-title {
  font-family: "Figtree-SemiBold";
  font-size: 1rem;
  color: #333;
}

.event-time {
  font-family: "Figtree-Regular";
  font-size: 0.85rem;
  color: #666;
  margin: 3px 0;
}

.event-details {
  font-family: "Figtree-Regular";
  font-size: 0.85rem;
  color: #007bff;
}

.calendar-pagination-box {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
}

.calendar-pagination-btn {
  background-color: #735dff26;
  color: #735dff;
  border: none;
  padding: 2px 5px;
  cursor: pointer;
  margin: 0 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.calendar-pagination-btn:hover {
  background-color: #735dff;
  color: #fff;
}

.calendar-pagination-btn:disabled {
  background-color: #e0e0e0;
  color: #999;
}

.calendar-page-number {
  font-size: 0.9rem;
  margin: 0 10px;
}
.rbc-day-slot .event-circle {
  background-color: #007bff; /* Customize the color */
  color: #fff;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 2;
}

.rbc-day-slot {
  position: relative; /* Ensure that the day slot is a positioned element */
}

.rbc-event {
  background: transparent; /* Make sure multi-day events don't overlap with the circle */
  color: #007bff;
  border: none;
}
