/* Main container */
.careHome-container {
  position: relative;
  padding: 1rem 1.2rem;
  margin-top: 60px;
  height: calc(100vh - 60px);
}

/* Header container for Create button and Legends */
.careHome-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Create Care Home button */
.careHome-invite-btn {
  background-color: #735dff;
  color: white;
  border: none;
  padding: 8px 16px;
  font-family: "Figtree-SemiBold";
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease;
}

.careHome-invite-btn:hover {
  background-color: #5933cc;
}

.careHome-invite-icon {
  margin-right: 5px;
}

/* Legends container */
.careHome-legends {
  display: flex;
  gap: 1rem;
}

.careHome-legend-item {
  display: flex;
  align-items: center;
  font-family: "Figtree-Regular";
  font-size: 0.9rem;
}

.careHome-legend-icon {
  font-size: 1.2rem;
  margin-right: 5px;
}

.careHome-legend-text {
  margin-left: 5px;
}

.careHome-legend-patients {
  color: #0cc763;
}
.careHome-legend-view {
  color: #ffb300;
}

.careHome-legend-update {
  color: #0c9cfc;
}

.careHome-legend-delete {
  color: #ff5a29;
}

/* Table container */
.careHome-table-container {
  margin-top: 20px;
  overflow: auto;
}

.careHome-table-container table {
  width: 100%;
  font-family: "Figtree-Regular";
  border: 1px solid #ddd;
}

.careHome-table-container th {
  background-color: #f9f9f9;
  font-family: "Figtree-SemiBold";
  font-size: 0.9rem;
  color: #333;
  text-align: left;
  padding: 10px;
}

.careHome-table-container td {
  padding: 10px;
  font-family: "Figtree-Regular";
  font-size: 0.9rem;
  color: #333;
  border-bottom: 1px solid #f0f0f0;
}

/* Action icons */
.careHome-action-icon {
  font-size: 25px;
  cursor: pointer;
  transition: color 0.3s ease, background-color 0.3s ease;
  padding: 5px;
  border-radius: 50%;
  margin-right: 5px;
}

.careHome-view-patients-icon {
  color: #0cc763;
}

.careHome-view-icon {
  color: #ffb300;
}

.careHome-edit-icon {
  color: #0c9cfc;
}

.careHome-delete-icon {
  color: #ff5a29;
}

.careHome-view-patients-icon:hover {
  background-color: #e6ffe6;
}

.careHome-view-icon:hover {
  background-color: #e6f5ff;
}

.careHome-edit-icon:hover {
  background-color: #fff6d2;
}

.careHome-delete-icon:hover {
  background-color: #ffe4e1;
}

/* Pagination */
.careHome-pagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.careHome-pagination-btn {
  background-color: #735dff26;
  color: #735dff;
  border: none;
  padding: 5px 15px;
  cursor: pointer;
  margin: 0 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
  font-family: "Figtree-SemiBold";
}

.careHome-pagination-btn:hover {
  background-color: #735dff;
  color: #fff;
}

.careHome-pagination-btn:disabled {
  background-color: #e0e0e0;
  color: #999;
}

.careHome-page-number {
  font-size: 0.9rem;
  font-family: "Figtree-Regular";
  color: #666;
}

/* Modal specific styles */
.careHome-modal .modal-header {
  background-color: #f9f9f9;
  border-bottom: 1px solid #ddd;
}

.careHome-modal .modal-title {
  font-family: "Figtree-SemiBold";
}

.careHome-modal .modal-body {
  font-family: "Figtree-Regular";
}

.careHome-modal .modal-footer button {
  font-family: "Figtree-SemiBold";
}

/* Offcanvas styles */
.careHome-offcanvas {
  width: 75% !important;
}

.careHome-offcanvas-header {
  background-color: #f9f9f9;
  border-bottom: 1px solid #ddd;
  padding: 1rem 1.5rem;
}

.careHome-offcanvas-header .btn-close {
  margin: 0;
}

.careHome-offcanvas-body {
  padding: 1.5rem;
}

.careHome-offcanvas-footer {
  padding: 1rem 1.5rem;
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  border-top: 1px solid #ddd;
  background-color: #f9f9f9;
}

.careHome-offcanvas-subtitle {
  font-family: "Figtree-SemiBold";
  color: #333;
  margin-bottom: 1.5rem;
  padding: 0 0.75rem;
}

/* Form styles within offcanvas */
.careHome-offcanvas .form-group {
  margin-bottom: 1.5rem;
}

.careHome-offcanvas .form-label {
  font-family: "Figtree-SemiBold";
  margin-bottom: 0.5rem;
}

.careHome-offcanvas .form-control {
  font-family: "Figtree-Regular";
  border-radius: 5px;
  border: 1px solid #ddd;
  padding: 0.5rem;
}

/* Details view styles */
.careHome-details-section {
  margin-bottom: 2rem;
}

.careHome-detail-item {
  margin-bottom: 1rem;
  font-family: "Figtree-Regular";
  padding: 0.5rem;
  border-bottom: 1px solid #f0f0f0;
}

.careHome-detail-item strong {
  font-family: "Figtree-SemiBold";
  margin-right: 0.5rem;
  color: #666;
}

/* Patients table styles */
.careHome-patients-table {
  margin-top: 1rem;
}

.careHome-patients-table th {
  background-color: #f9f9f9;
  font-family: "Figtree-SemiBold";
  color: #333;
}

.careHome-patients-table td {
  font-family: "Figtree-Regular";
}

.careHome-no-patients {
  text-align: center;
  color: #666;
  padding: 2rem;
  font-family: "Figtree-Regular";
}

/* Button styles within offcanvas */
.careHome-offcanvas .btn {
  font-family: "Figtree-SemiBold";
  padding: 0.5rem 1rem;
}

.careHome-offcanvas .btn-primary {
  background-color: #735dff;
  border-color: #735dff;
}

.careHome-offcanvas .btn-primary:hover {
  background-color: #5933cc;
  border-color: #5933cc;
}

.careHome-delete-modal .modal-content {
  border-radius: 8px;
}

.careHome-delete-modal .modal-header {
  background-color: #f8d7da;
  border-bottom: 1px solid #f5c6cb;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.careHome-delete-modal .modal-title {
  color: #721c24;
  font-family: "Figtree-SemiBold";
}

.careHome-delete-modal .modal-body {
  padding: 1.5rem;
  font-family: "Figtree-Regular";
}

.careHome-delete-modal .modal-footer {
  border-top: 1px solid #dee2e6;
  padding: 1rem;
}

.careHome-delete-modal .btn-danger {
  background-color: #ff5a29;
  border-color: #ff5a29;
}

.careHome-delete-modal .btn-danger:hover {
  background-color: #e64016;
  border-color: #e64016;
}
