.search-navigation {
  background: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 0.375rem;
  padding: 0.75rem;
  margin: 1rem 0;
  text-align: center;
}

.search-navigation-link {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  color: #0d6efd;
  text-decoration: none;
  font-weight: 500;
}

.search-navigation-link:hover {
  color: #0a58ca;
  text-decoration: underline;
}

.search-navigation-icon {
  font-size: 1.25rem;
}
