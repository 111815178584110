/* Main container */
.delivery-list-container {
  position: relative;
  padding: 1rem 1.2rem;
  margin-top: 60px;
  height: calc(100vh - 60px);
}

/* Header container for Create button and Legends */
.delivery-list-header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

/* Create Care Home button */
.delivery-list-invite-btn {
  background-color: #735dff;
  color: white;
  border: none;
  padding: 8px 16px;
  font-family: "Figtree-SemiBold";
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease;
}

.delivery-list-invite-btn:hover {
  background-color: #5933cc;
}

.delivery-list-invite-icon {
  margin-right: 5px;
}

/* Legends container */
.delivery-list-legends {
  display: flex;
  gap: 1rem;
}

.delivery-list-legend-item {
  display: flex;
  align-items: center;
  font-family: "Figtree-Regular";
  font-size: 0.9rem;
}

.delivery-list-legend-icon {
  font-size: 1.2rem;
  margin-right: 5px;
}

.delivery-list-legend-text {
  margin-left: 5px;
}

.delivery-list-legend-delivery-lists {
  color: #0cc763;
}
.delivery-list-legend-view {
  color: #ffb300;
}

.delivery-list-legend-update {
  color: #0c9cfc;
}

.delivery-list-legend-delete {
  color: #ff5a29;
}

/* Table container */
.delivery-list-table-container {
  margin-top: 20px;
  overflow: auto;
}

.delivery-list-table-container table {
  width: 100%;
  font-family: "Figtree-Regular";
  border: 1px solid #ddd;
}

.delivery-list-table-container th {
  background-color: #f9f9f9;
  font-family: "Figtree-SemiBold";
  font-size: 0.9rem;
  color: #333;
  text-align: left;
  padding: 10px;
}

.delivery-list-table-container td {
  padding: 10px;
  font-family: "Figtree-Regular";
  font-size: 0.9rem;
  color: #333;
  border-bottom: 1px solid #f0f0f0;
}

/* Action icons */
.delivery-list-action-icon {
  font-size: 25px;
  cursor: pointer;
  transition: color 0.3s ease, background-color 0.3s ease;
  padding: 5px;
  border-radius: 50%;
  margin-right: 5px;
}

.delivery-list-view-delivery-lists-icon {
  color: #0cc763;
}

.delivery-list-view-icon {
  color: #ffb300;
}

.delivery-list-edit-icon {
  color: #0c9cfc;
}

.delivery-list-delete-icon {
  color: #ff5a29;
}

.delivery-list-view-delivery-lists-icon:hover {
  background-color: #e6ffe6;
}

.delivery-list-view-icon:hover {
  background-color: #e6f5ff;
}

.delivery-list-edit-icon:hover {
  background-color: #fff6d2;
}

.delivery-list-delete-icon:hover {
  background-color: #ffe4e1;
}

/* Pagination */
.delivery-list-pagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.delivery-list-pagination-btn {
  background-color: #735dff26;
  color: #735dff;
  border: none;
  padding: 5px 15px;
  cursor: pointer;
  margin: 0 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
  font-family: "Figtree-SemiBold";
}

.delivery-list-pagination-btn:hover {
  background-color: #735dff;
  color: #fff;
}

.delivery-list-pagination-btn:disabled {
  background-color: #e0e0e0;
  color: #999;
}

.delivery-list-page-number {
  font-size: 0.9rem;
  font-family: "Figtree-Regular";
  color: #666;
}

/* Modal specific styles */
.delivery-list-modal .modal-header {
  background-color: #f9f9f9;
  border-bottom: 1px solid #ddd;
}

.delivery-list-modal .modal-title {
  font-family: "Figtree-SemiBold";
}

.delivery-list-modal .modal-body {
  font-family: "Figtree-Regular";
}

.delivery-list-modal .modal-footer button {
  font-family: "Figtree-SemiBold";
}

.delivery-list-search-input-box {
  width: 100%;
  margin-bottom: 10px;
}
.delivery-list-search-input {
  width: 100%;
}
.delivery-list-legend-edit-delivery-icon {
  color: #5933cc;
}
.delivery-list-reassign-icon {
  color: #ffb300;
}
.delivery-list-legend-carehome-address {
  color: #0cc763;
}
.delivery-list-legend-cancelled-icon {
  color: #dc3545;
}
.delivery-list-accordion-header {
  display: flex;
  align-items: center;
}

.delivery-list-accordion-patient-name {
  font-size: 1rem;
  font-family: "Figtree-SemiBold";
  margin: 0 0 2px 0;
}

.delivery-list-accordion-patient-nhs {
  font-family: "Figtree-Italic";
  font-size: 0.8rem;
  color: #666;
  margin: 0;
}

.delivery-list-accordion-patient-address {
  font-family: "Figtree-Regular";
  font-size: 0.9rem;
  color: #666;
  margin: 0 0 0 10px;
}

.delivery-list-create-patient-address {
  display: flex;
  align-items: center;
}

.delivery-list-create-patient-carehome-name {
  font-family: "Figtree-Regular";
  font-size: 0.9rem;
  color: #666;
  margin: 0 0 0 10px;
}

.delivery-list-edit-delivery-button-container {
  display: flex;
  justify-content: space-around;
}

.delivery-list-edit-delivery-button {
  background-color: #735dff;
  color: white;
  border: none;
  padding: 2px 10px;
  font-family: "Figtree-SemiBold";
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease;
}

.delivery-list-edit-delivery-button:hover {
  background-color: #5933cc;
}

.delivery-list-reassign-shelf-button {
  background-color: #ffb300;
  color: white;
  border: none;
  padding: 2px 10px;
  font-family: "Figtree-SemiBold";
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  align-items: center;
  margin-left: 5px;
  transition: background-color 0.3s ease;
}

.delivery-list-reassign-shelf-button:hover {
  background-color: #ffb300;
}

.delivery-list-cancel-button {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 2px 10px;
  font-family: "Figtree-SemiBold";
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease;
}

.delivery-list-cancel-button:hover {
  background-color: #dc3545;
}

.delivery-list-delivery-status-text {
  font-size: 0.9rem;
  margin-left: 5px;
  transition: color 0.3s ease;
  color: #6c757d;
  text-decoration: none;
  font-family: "Figtree-SemiBold";
}

.delivery-list-delivery-status-icon {
  margin-left: 5px;
}

.delivery-list-accordion-container {
  height: calc(100vh - 300px);
  overflow: auto;
}

.delivery-list-items-per-page-selector {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 10px 0;
}

.delivery-list-form-select {
  border: 1px solid #5933cc !important;
  background-color: #f8f9fa !important;
  color: #5933cc !important;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  outline: none;
  border-radius: 4px;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  height: fit-content;
  padding: 2px 5px;
  line-height: normal;
  height: auto;
}

.delivery-list-items-per-page-option {
  font-size: 0.9rem;
  padding: 5px 10px;
  margin: 0 5px;
}

.delivery-list-sort-button {
  color: #000;
  text-decoration: none;
  font-size: 1rem;
  cursor: pointer;
  transition: color 0.3s ease, background-color 0.3s ease;
  border: none;
}
.delivery-list-sort-button:hover {
  color: #5933cc;
}
.delivery-list-sort-button:focus {
  color: #5933cc;
}
.delivery-list-handouts-link-container {
  display: flex;
  align-items: center;
  margin: 5px 0;
  text-decoration: underline;
}

.delivery-list-handouts-link {
  font-family: "Figtree-Regular";
  font-size: 0.8rem;
  color: #0d6efd !important;
  margin: 0 0 0 10px;
}
.delivery-list-select-wrapper {
  position: relative;
  width: 100%;
}

.delivery-list-select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding-right: 25px !important; /* Make room for the arrow */
  width: 100%;
}

.delivery-list-select-arrow {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  font-size: 18px;
  color: #666;
}

.delivery-list-handouts-icon {
  font-size: 1.2rem;
  margin-right: 5px;
}

/* Offcanvas styles */
.delivery-list-offcanvas {
  width: 75% !important;
}

.delivery-list-offcanvas-header {
  background-color: #f9f9f9;
  border-bottom: 1px solid #ddd;
  padding: 1rem 1.5rem;
}

.delivery-list-offcanvas-header .btn-close {
  margin: 0;
}

.delivery-list-offcanvas-body {
  padding: 1.5rem;
}

.delivery-list-offcanvas-form {
  width: 100%;
}

.delivery-list-offcanvas-footer {
  padding: 1rem 1.5rem;
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  border-top: 1px solid #ddd;
  background-color: #f9f9f9;
}

.delivery-list-offcanvas-title {
  font-family: "Figtree-SemiBold";
  color: #333;
  margin-bottom: 1.5rem;
  padding: 0 0.75rem;
}

/* Form styles within offcanvas */
.delivery-list-offcanvas .form-group {
  margin-bottom: 1.5rem;
}

.delivery-list-offcanvas .form-label {
  font-family: "Figtree-SemiBold";
  margin-bottom: 0.5rem;
}

.delivery-list-offcanvas .form-control {
  font-family: "Figtree-Regular";
  border-radius: 5px;
  border: 1px solid #ddd;
  padding: 0.5rem;
}

/* Detail view styles */
.delivery-list-details-section {
  margin-bottom: 2rem;
}

.delivery-list-detail-item {
  margin-bottom: 1rem;
  font-family: "Figtree-Regular";
  padding: 0.5rem;
  border-bottom: 1px solid #f0f0f0;
}

.delivery-list-detail-item strong {
  font-family: "Figtree-SemiBold";
  margin-right: 0.5rem;
  color: #666;
}

/* Delete Modal specific styles */
.delivery-list-delete-modal .modal-content {
  border-radius: 8px;
}

.delivery-list-delete-modal .modal-header {
  background-color: #f8d7da;
  border-bottom: 1px solid #f5c6cb;
}

.delivery-list-delete-modal .modal-title {
  color: #721c24;
  font-family: "Figtree-SemiBold";
}

.delivery-list-delete-modal .modal-body {
  padding: 1.5rem;
  font-family: "Figtree-Regular";
}

/* Confirmation Modal specific styles */
.delivery-list-confirmation-modal .modal-content {
  border-radius: 8px;
}

.delivery-list-confirmation-modal .modal-header {
  background-color: #f8d7da;
  border-bottom: 1px solid #f5c6cb;
}

.delivery-list-confirmation-modal .modal-title {
  color: #721c24;
  font-family: "Figtree-SemiBold";
}

.delivery-list-confirmation-modal .modal-body {
  padding: 1.5rem;
  font-family: "Figtree-Regular";
}

/* Button styles */
.delivery-list-confirmation-modal .btn-primary {
  background-color: #735dff;
  border-color: #735dff;
}

.delivery-list-confirmation-modal .btn-primary:hover {
  background-color: #5933cc;
  border-color: #5933cc;
}
