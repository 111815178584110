.delivery-btn{
  border: none;
  color: white;
  font-size: 22px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}
.delivery-btn a{
  text-decoration: none;
  color: white;
}
.deli-button{
background-color: rgb(165, 122, 42);
}
.routes-button{
  background-color: rgb(132, 165, 42);
  
}
.track-button{
  background-color: rgb(42, 165, 97);
  
}
.staff-button{
  background-color: rgb(42, 165, 159);
  
}
.patient-button{
  
  background-color: rgb(42, 54, 165);
}
.report-button{
  background-color: rgb(165, 42, 155);

}