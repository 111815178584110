.storage-container {
  position: relative;
  padding: 1rem 1.2rem;
  margin-top: 60px;
  height: calc(100vh - 60px);
}
.storage-search-input-box {
  width: 100%;
  margin-bottom: 10px;
}
.storage-search-input {
  width: 100%;
}
.storage-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
/* Legends container */
.storage-legends {
  display: flex;
  gap: 1rem;
}
.storage-legend-item {
  display: flex;
  align-items: center;
  font-family: "Figtree-Regular";
  font-size: 0.9rem;
}

.storage-legend-icon {
  font-size: 1.2rem;
  margin-right: 5px;
}

.storage-legend-text {
  margin-left: 5px;
}

/* Action icons */
.storage-action-icon {
  font-size: 1.2rem;
  cursor: pointer;
  transition: color 0.3s ease, background-color 0.3s ease;
  margin-right: 5px;
}

.storage-legend-patients-address {
  color: #ffb300;
}

.storage-legend-carehome-address {
  color: #0cc763;
}

.storage-legend-collection-icon {
  color: #0c9cfc;
}

.storage-add-icon {
  color: #0c9cfc;
}
.storage-add-icon:hover {
  background-color: #e6ffe6;
}

.storage-view-shelf-btn {
  background-color: #735dff;
  color: white;
  border: none;
  padding: 8px 16px;
  font-family: "Figtree-SemiBold";
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease;
}

.storage-view-shelf-btn:hover {
  background-color: #5933cc;
}

.storage-invite-icon {
  margin-right: 5px;
}

/* Table */
.storage-table-container {
  margin-top: 20px;
  overflow: auto;
}

.storage-table-container table {
  width: 100%;
  font-family: "Figtree-Regular";
  border: 1px solid #ddd;
}

.storage-table-container th {
  background-color: #f9f9f9;
  font-family: "Figtree-SemiBold";
  font-size: 0.9rem;
  color: #333;
  text-align: left;
  padding: 10px;
}

.storage-table-container td {
  padding: 10px;
  font-family: "Figtree-Regular";
  font-size: 0.9rem;
  color: #333;
  border-bottom: 1px solid #f0f0f0;
}

/* Pagination */
.storage-pagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.storage-pagination-btn {
  background-color: #735dff26;
  color: #735dff;
  border: none;
  padding: 5px 15px;
  cursor: pointer;
  margin: 0 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
  font-family: "Figtree-SemiBold";
}

.storage-pagination-btn:hover {
  background-color: #735dff;
  color: #fff;
}

.storage-pagination-btn:disabled {
  background-color: #e0e0e0;
  color: #999;
}

.storage-page-number {
  font-size: 0.9rem;
  font-family: "Figtree-Regular";
  color: #666;
}

/* Offcanvas styles */

/* Offcanvas styles */
.medicine-storage-offcanvas {
  width: 75% !important;
}

.medicine-storage-offcanvas-header {
  background-color: #f9f9f9;
  border-bottom: 1px solid #ddd;
  padding: 1rem 1.5rem;
}

.medicine-storage-offcanvas-header .btn-close {
  margin: 0;
}

.medicine-storage-offcanvas-body {
  padding: 1.5rem;
}

.medicine-storage-offcanvas-form {
  width: 100%;
}

.medicine-storage-offcanvas-footer {
  padding: 1rem 1.5rem;
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  border-top: 1px solid #ddd;
  background-color: #f9f9f9;
}

.medicine-storage-offcanvas-title {
  font-family: "Figtree-SemiBold";
  color: #333;
  margin-bottom: 1.5rem;
  padding: 0 0.75rem;
}

/* Form styles within offcanvas */
.medicine-storage-offcanvas .form-group {
  margin-bottom: 1.5rem;
}

.medicine-storage-offcanvas .form-label {
  font-family: "Figtree-SemiBold";
  margin-bottom: 0.5rem;
}

.medicine-storage-offcanvas .form-control {
  font-family: "Figtree-Regular";
  border-radius: 5px;
  border: 1px solid #ddd;
  padding: 0.5rem;
}

/* Detail view styles */
.medicine-storage-details-section {
  margin-bottom: 2rem;
}

.medicine-storage-detail-item {
  margin-bottom: 1rem;
  font-family: "Figtree-Regular";
  padding: 0.5rem;
  border-bottom: 1px solid #f0f0f0;
}

.medicine-storage-detail-item strong {
  font-family: "Figtree-SemiBold";
  margin-right: 0.5rem;
  color: #666;
}
