.mix-graph-container {
  background-color: #f9f9f9;
  border-radius: 15px;
}

.shelf-dashboard-title {
  font-family: "Figtree-SemiBold";
  font-size: 1.2rem; /* Smaller font size for title */
  color: #333;
  margin-bottom: 15px;
}

.shelves-title-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.shelves-percentage-comparison-box {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 15px;
}

.shelf-percentage-comparison {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: "Figtree-Regular";
  color: #333;
  margin: 0 20px;
  justify-content: space-between;
}

.shelf-name {
  font-size: 0.9rem; /* Smaller font size for shelf names */
  font-weight: bold;
}

.percentage-box {
  display: flex;
  align-items: center;
}

.shelf-percentage-value {
  font-size: 0.9rem; /* Smaller font size for percentage values */
  font-weight: bold;
}

.shelf-percentage-value.updated {
  color: #0cc763; /* Green color for updated shelves */
}

.shelf-percentage-value.not-updated {
  color: #ff5a29; /* Red color for not updated shelves */
}

.trend-icon {
  margin-left: 5px;
  font-size: 1rem; /* Icon size */
}

.shelf-percentage-comparison-right {
  font-size: 0.9rem; /* Smaller font size for the right side */
  font-family: "Figtree-Regular";
}
