.prescription-requests-container {
  padding: 15px;
  background-color: #f9f9f9;
}

.prescription-dashboard-title {
  font-family: "Figtree-SemiBold";
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 15px;
}

.request-cards-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.request-card {
  padding: 5px 10px;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.request-card-content {
  display: flex;
  flex-direction: column;
}

.event-title {
  font-family: "Figtree-SemiBold";
  font-size: 1rem;
}

.event-time {
  font-family: "Figtree-Regular";
  font-size: 0.85rem;
  margin: 3px 0;
}

.event-label {
  font-family: "Figtree-Regular";
  font-size: 0.85rem;
  margin-top: 5px;
}

.request-card-action {
  display: flex;
  align-items: center;
}

.prescription-dashboard-pagination-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
}

.prescription-dashboard-pagination-btn {
  background-color: #735dff26;
  color: #735dff;
  border: none;
  padding: 5px 15px;
  cursor: pointer;
  margin: 0 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.prescription-dashboard-pagination-btn:hover {
  background-color: #735dff;
  color: #fff;
}

.prescription-dashboard-pagination-btn:disabled {
  background-color: #e0e0e0;
  color: #999;
}

.prescription-dashboard-page-number {
  font-size: 0.9rem;
  margin: 0 10px;
}
