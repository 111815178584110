/* General */

/* Fonts */

@font-face {
  font-family: "Figtree-Light";
  src: url("../public/fonts/Figtree/static/Figtree-Light.ttf")
    format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "Figtree-Regular";
  src: url("../public/fonts/Figtree/static/Figtree-Regular.ttf")
    format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "Figtree-Medium";
  src: url("../public/fonts/Figtree/static/Figtree-Medium.ttf")
    format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "Figtree-SemiBold";
  src: url("../public/fonts/Figtree/static/Figtree-SemiBold.ttf")
    format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "Figtree-Bold";
  src: url("../public/fonts/Figtree/static/Figtree-Bold.ttf") format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "Figtree-ExtraBold";
  src: url("../public/fonts/Figtree/static/Figtree-ExtraBold.ttf")
    format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "Figtree-Black";
  src: url("../public/fonts/Figtree/static/Figtree-Black.ttf")
    format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "Figtree-LightItalic";
  src: url("../public/fonts/Figtree/static/Figtree-LightItalic.ttf")
    format("truetype");
  font-style: italic;
}

@font-face {
  font-family: "Figtree-Italic";
  src: url("../public/fonts/Figtree/static/Figtree-Italic.ttf")
    format("truetype");
  font-style: italic;
}

@font-face {
  font-family: "Figtree-MediumItalic";
  src: url("../public/fonts/Figtree/static/Figtree-MediumItalic.ttf")
    format("truetype");
  font-style: italic;
}

@font-face {
  font-family: "Figtree-SemiBoldItalic";
  src: url("../public/fonts/Figtree/static/Figtree-SemiBoldItalic.ttf")
    format("truetype");
  font-style: italic;
}

@font-face {
  font-family: "Figtree-BoldItalic";
  src: url("../public/fonts/Figtree/static/Figtree-BoldItalic.ttf")
    format("truetype");
  font-style: italic;
}

@font-face {
  font-family: "Figtree-ExtraBoldItalic";
  src: url("../public/fonts/Figtree/static/Figtree-ExtraBoldItalic.ttf")
    format("truetype");
  font-style: italic;
}

@font-face {
  font-family: "Figtree-BlackItalic";
  src: url("../public/fonts/Figtree/static/Figtree-BlackItalic.ttf")
    format("truetype");
  font-style: italic;
}
/* Fonts  End */

/* Text Styles */
.light-text {
  font-family: "Figtree-Light", sans-serif;
}

.regular-text {
  font-family: "Figtree-Regular", sans-serif;
}

.medium-text {
  font-family: "Figtree-Medium", sans-serif;
}

.semibold-text {
  font-family: "Figtree-SemiBold", sans-serif;
}

.bold-text {
  font-family: "Figtree-Bold", sans-serif;
}

.extra-bold-text {
  font-family: "Figtree-ExtraBold", sans-serif;
}

.black-text {
  font-family: "Figtree-Black", sans-serif;
}

.light-italic-text {
  font-family: "Figtree-LightItalic", sans-serif;
}

.italic-text {
  font-family: "Figtree-Italic", sans-serif;
}

.medium-italic-text {
  font-family: "Figtree-MediumItalic", sans-serif;
}

.semibold-italic-text {
  font-family: "Figtree-SemiBoldItalic", sans-serif;
}

.bold-italic-text {
  font-family: "Figtree-BoldItalic", sans-serif;
}

.extra-bold-italic-text {
  font-family: "Figtree-ExtraBoldItalic", sans-serif;
}

.black-italic-text {
  font-family: "Figtree-BlackItalic", sans-serif;
}
/* Text Styles  End */

/* Colors */
.color-primary {
  color: #735dff;
}
.color-muted {
  color: #98a5c3;
}
.color-yellow {
  color: #ffcb00;
}
.color-orange {
  color: #ff5a29;
}
.color-blue {
  color: #0c9cfc;
}
/* Colors End */

.medtrakr-btn {
  border: none;
  background-color: #07bfb5;
  padding: 5px 10px;
  border-radius: 10px;
  color: #fff;
}
.medtrakr-btn:hover {
  border: 1px solid #07bfb5;
  background-color: #fff;
  padding: 4.5px 9.5px;
  border-radius: 10px;
  color: #07bfb5;
}
.search-input-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.search-input-box > input {
  max-width: 80%;
}
.mr-10 {
  margin-right: 10px;
}
.mb-30 {
  margin-bottom: 30px;
}

.view {
  background-color: #e0f6f6 !important;
  color: #4b6c70 !important;
}

.view:hover {
  background-color: #e0f6f6 !important;
  color: #4b6c70 !important;
}

.update {
  background-color: #e2f6ed !important;
  color: #2caf48 !important;
}

.update:hover {
  background-color: #e2f6ed !important;
  color: #2caf48 !important;
}

.delete:hover {
  background-color: #fde2e7 !important;
  color: #ea3c5d !important;
}

.delete {
  background-color: #fde2e7 !important;
  color: #ea3c5d !important;
}
@media screen and (max-width: 600px) {
  .search-input-box {
    flex-direction: column;
  }
  .search-input-box > input {
    margin: 10px 0;
  }
}
