.mds-repeat-cycle-container {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 15px;
}
.cycle-dashboard-top-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.cycle-dashboard-title {
  font-family: "Figtree-SemiBold";
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 5px;
}

.mdsGraphButtonBox button {
  background-color: #735dff26;
  color: #735dff;
  border: none;
  padding: 5px 15px;
  margin: 0;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.mdsGraphButtonBox button:not(:last-child) {
  border-right: 1px solid #735dff;
}

.mdsGraphButtonBox button:first-child {
  border-radius: 5px 0 0 5px;
}

.mdsGraphButtonBox button:last-child {
  border-radius: 0 5px 5px 0;
}

.mdsGraphButtonBox button:hover {
  background-color: #735dff;
  color: #fff;
}

.mdsGraphButtonBox .active-months {
  background-color: #735dff;
  color: white;
  border: none;
}
.cycle-dashboard-para {
  font-size: 0.8rem;
  font-family: "Figtree-Regular";
  color: "#e0e0e0";
  margin-bottom: 10px;
}
