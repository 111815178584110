.video-container {
  position: relative;
  height: 95vh;
  overflow: hidden;
}

#background-video {
  position: absolute;
  top: 0; 
  left: 0; 
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.text-content {
  position: absolute;
  top: 64%;
  left: 10%; 
  transform: translate(0, -50%);
  color: #fff;
  text-align: left;
  max-width: 40%; 
}

.text-content h1 {
  font-size: 65px; 
  margin-bottom: 10px;
  font-weight: bold;
  color:#024945 ;

}

.text-content p {
  font-size: 24px; 
  color: darkslategrey;
  font-weight: normal;
}
.action-buttons {
  display: flex;
  justify-content: start;
}

.action-buttons button {
  margin: 0 10px;
}
.btn-login{
  background-color: #024945 !important;
  color: white;
  padding: 12px 30px;
  font-size: 20px;
}

.btn-login:hover {
  background-color: #024945 !important;
  color: white;
}


.hero-heading{
     font-size: 62px;
     font-weight: bold;
     padding-left: 150px;
}
.list-none{
  list-style: none;
  text-decoration: none;


}


@media screen and (max-width: 768px) {
  .text-content {
      max-width: 80%;
  }

  .text-content h1 {
    font-size: 62px; 
    margin-bottom: 10px;
    font-weight: bold;
  }

  .text-content p {
    font-size: 20px;
     
  }
  .action-buttons {
    display: flex;
    justify-content: start;
  }
  
  .action-buttons button {
    margin: 0 10px;
  }
  .btn-login{
    background-color: #024945 !important;
    color: white;
    padding: 12px 30px;
    font-size: 20px;
  }
  
  .btn-login:hover {
    background-color: #024945 !important;
    color: white;
  }
  .video-paragraph{
       font-size: 20px !important;
 

  }
  .text-content {
    position: absolute;
    top: 68%;
    left: 10%;
    transform: translate(0, -50%);
    color: #fff;
    text-align: left;
    max-width: 40%;
  }
}
