/* Main container */
.routes-container {
  position: relative;
  padding: 1rem 1.2rem;
  margin-top: 60px;
  height: calc(100vh - 60px);
}

/* Header container for Add button */
.routes-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Add routes button */
.routes-create-btn {
  background-color: #735dff;
  color: white;
  border: none;
  padding: 8px 16px;
  font-family: "Figtree-SemiBold";
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease;
}

.routes-create-btn:hover {
  background-color: #5933cc;
}

.routes-icon {
  margin-right: 5px;
}

/* Table container */
.routes-table-container {
  margin-top: 20px;
  overflow: auto;
}

.routes-table-container table {
  width: 100%;
  font-family: "Figtree-Regular";
  border: 1px solid #ddd;
}

.routes-table-container th {
  background-color: #f9f9f9;
  font-family: "Figtree-SemiBold";
  font-size: 0.9rem;
  color: #333;
  text-align: left;
  padding: 10px;
}

.routes-table-container td {
  padding: 10px;
  font-family: "Figtree-Regular";
  font-size: 0.9rem;
  color: #333;
  border-bottom: 1px solid #f0f0f0;
}

.routes-legends {
  display: flex;
  gap: 1rem;
}

.routes-legend-item {
  display: flex;
  align-items: center;
  font-family: "Figtree-Regular";
  font-size: 0.9rem;
}

.routes-action-icon {
  font-size: 25px;
  cursor: pointer;
  transition: color 0.3s ease, background-color 0.3s ease;
  padding: 5px;
  border-radius: 50%;
  margin-right: 5px;
}

.routes-legend-icon {
  font-size: 1.2rem;
  margin-right: 5px;
}

.routes-legend-text {
  margin-left: 5px;
}

.routes-legend-view {
  color: #ffb300;
}
.routes-legend-update {
  color: #0c9cfc;
}

.routes-legend-delete {
  color: #ff5a29;
}

/* Action buttons styling */
.routes-view-icon {
  color: #ffb300;
}

.routes-edit-icon {
  color: #0c9cfc;
}

.routes-delete-icon {
  color: #ff5a29;
}

/* Pagination */
.routes-pagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.routes-pagination-btn {
  background-color: #735dff26;
  color: #735dff;
  border: none;
  padding: 5px 15px;
  cursor: pointer;
  margin: 0 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
  font-family: "Figtree-SemiBold";
}

.routes-pagination-btn:hover {
  background-color: #735dff;
  color: #fff;
}

.routes-pagination-btn:disabled {
  background-color: #e0e0e0;
  color: #999;
}

.routes-page-number {
  font-size: 0.9rem;
  font-family: "Figtree-Regular";
  color: #666;
}
/* Offcanvas styles */
.routes-offcanvas {
  width: 75% !important;
}

.routes-offcanvas-header {
  background-color: #f9f9f9;
  border-bottom: 1px solid #ddd;
  padding: 1rem 1.5rem;
}

.routes-offcanvas-header .btn-close {
  margin: 0;
}

.routes-offcanvas-body {
  padding: 1.5rem;
}

.routes-offcanvas-footer {
  padding: 1rem 1.5rem;
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  border-top: 1px solid #ddd;
  background-color: #f9f9f9;
}

/* Form styles within offcanvas */
.routes-form-group {
  margin-bottom: 1.5rem;
}

.routes-form-group label {
  font-family: "Figtree-SemiBold";
  margin-bottom: 0.5rem;
  display: block;
}

.routes-form-group input,
.routes-form-group select {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-family: "Figtree-Regular";
}

.routes-form-check {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

/* Detail view styles */
.routes-detail-item {
  margin-bottom: 1rem;
  padding: 0.5rem;
  border-bottom: 1px solid #f0f0f0;
}

.routes-detail-item strong {
  font-family: "Figtree-SemiBold";
  margin-right: 0.5rem;
  color: #666;
}

/* Delete Modal specific styles */
.routes-delete-modal .modal-content {
  border-radius: 8px;
}

.routes-delete-modal .modal-header {
  background-color: #f8d7da;
  border-bottom: 1px solid #f5c6cb;
}

.routes-delete-modal .modal-title {
  color: #721c24;
  font-family: "Figtree-SemiBold";
}

.routes-delete-modal .modal-body {
  padding: 1.5rem;
  font-family: "Figtree-Regular";
}

/* Button styles */
.routes-offcanvas .btn-primary {
  background-color: #735dff;
  border-color: #735dff;
}

.routes-offcanvas .btn-primary:hover {
  background-color: #5933cc;
  border-color: #5933cc;
}

.routes-delete-modal .btn-danger {
  background-color: #ff5a29;
  border-color: #ff5a29;
}

.routes-delete-modal .btn-danger:hover {
  background-color: #e64016;
  border-color: #e64016;
}
