/* Inheriting base styles from switch-pharmacy.css */
.switch-pharmacy-container {
  position: relative;
  padding: 1rem 1.2rem;
  margin-top: 60px;
  height: calc(100vh - 60px);
  overflow: auto;
}

/* Invite Button */
.switch-pharmacy-invite-btn {
  background-color: #735dff;
  color: white;
  border: none;
  padding: 8px 16px;
  font-family: "Figtree-SemiBold";
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease;
}

.switch-pharmacy-invite-btn:hover {
  background-color: #5933cc;
}

.switch-pharmacy-invite-icon {
  margin-right: 5px;
}

/* Pharmacy Cards Specific Styles */
.switch-pharmacy-cards-container {
  margin-top: 20px;
  max-height: calc(100vh - 250px);
  padding: 0.5rem;
}

.switch-pharmacy-card {
  background: white;
  border-radius: 10px;
  padding: 1.5rem;
  height: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  border: 1px solid #e0e0e0;
  position: relative;
}

.switch-pharmacy-card:hover {
  box-shadow: 0 4px 8px rgba(115, 93, 255, 0.15);
  transform: translateY(-2px);
}

.switch-pharmacy-card-active {
  border: 2px solid #735dff;
  background-color: #735dff0a;
}

.switch-pharmacy-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  border-bottom: 1px solid #eee;
  padding-bottom: 0.5rem;
}

.switch-pharmacy-card-header h3 {
  font-family: "Figtree-SemiBold";
  font-size: 1.2rem;
  color: #333;
  margin: 0;
}

.switch-pharmacy-active-icon {
  color: #735dff;
  font-size: 1.5rem;
}

.switch-pharmacy-card-body {
  font-family: "Figtree-Regular";
  font-size: 0.9rem;
}

.switch-pharmacy-card-body p {
  margin-bottom: 0.5rem;
  color: #666;
}

.switch-pharmacy-switch-btn {
  width: 100%;
  background-color: #735dff26;
  color: #735dff;
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  margin-top: 1rem;
  font-family: "Figtree-SemiBold";
  transition: all 0.3s ease;
}

.switch-pharmacy-switch-btn:hover {
  background-color: #735dff;
  color: white;
}

/* Pagination */

/* Pagination */
.switch-pharmacy-pagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.switch-pharmacy-pagination-btn {
  background-color: #735dff26;
  color: #735dff;
  border: none;
  padding: 5px 15px;
  cursor: pointer;
  margin: 0 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
  font-family: "Figtree-SemiBold";
}

.switch-pharmacy-pagination-btn:hover {
  background-color: #735dff;
  color: #fff;
}

.switch-pharmacy-pagination-btn:disabled {
  background-color: #e0e0e0;
  color: #999;
}

.switch-pharmacy-page-number {
  font-size: 0.9rem;
  font-family: "Figtree-Regular";
  color: #666;
}

/* Legends */
.switch-pharmacy-legends {
  display: flex;
  gap: 1rem;
}

.switch-pharmacy-legend-item {
  display: flex;
  align-items: center;
  font-family: "Figtree-Regular";
  font-size: 0.9rem;
}

.switch-pharmacy-legend-icon {
  font-size: 1.2rem;
  margin-right: 5px;
}

.switch-pharmacy-legend-text {
  margin-left: 5px;
}

.switch-pharmacy-legend-update {
  color: #0c9cfc;
}

.switch-pharmacy-legend-view {
  color: #ffb300;
}

/* Modal specific styles */
.switch-pharmacy-modal .modal-header {
  background-color: #f9f9f9;
  border-bottom: 1px solid #ddd;
}

.switch-pharmacy-modal .modal-title {
  font-family: "Figtree-SemiBold";
}

.switch-pharmacy-modal .modal-body {
  font-family: "Figtree-Regular";
}

.switch-pharmacy-modal .modal-footer button {
  font-family: "Figtree-SemiBold";
}

/* Offcanvas styles */
.switch-pharmacy-offcanvas {
  width: 75% !important;
}

.switch-pharmacy-offcanvas-header {
  background-color: #f9f9f9;
  border-bottom: 1px solid #ddd;
  padding: 1rem 1.5rem;
}

.switch-pharmacy-offcanvas-header .btn-close {
  margin: 0;
}

.switch-pharmacy-offcanvas-body {
  padding: 1.5rem;
}

.switch-pharmacy-offcanvas-footer {
  padding: 1rem 1.5rem;
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  border-top: 1px solid #ddd;
  background-color: #f9f9f9;
}

.switch-pharmacy-offcanvas-title {
  font-family: "Figtree-SemiBold";
  color: #333;
  margin-bottom: 1.5rem;
  padding: 0 0.75rem;
}

/* Form styles within offcanvas */
.switch-pharmacy-offcanvas .form-group {
  margin-bottom: 1.5rem;
}

.switch-pharmacy-offcanvas .form-label {
  font-family: "Figtree-SemiBold";
  margin-bottom: 0.5rem;
}

.switch-pharmacy-offcanvas .form-control {
  font-family: "Figtree-Regular";
  border-radius: 5px;
  border: 1px solid #ddd;
  padding: 0.5rem;
}
