.login-container {
  width: 100%;
}
.login-container .card {
  background-color: #ffffff;
}
.login-container .card label,
h2 {
  color: #6495eda8;
  font-size: 20px;
}

.login-container h2 {
  margin-bottom: 30px;
  color: #6495eda8;
}

.form-group {
  margin-bottom: 20px;
}

.login-container label {
  font-weight: bold;
}

.login-button {
  display: block;
  width: 100%;
  padding: 10px;
  background: #6495eda8;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  margin: 20px 0;
}

.login-button:hover {
  border: 1px solid #6495eda8;
  background: none !important;
  color: #6495eda8 !important;
}
.login-footer {
  display: flex;
  justify-content: space-between;
}
.login-footer a {
  text-decoration: none;
  font-size: 0.9rem;
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.login-footer a::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 1px;
  background-color: currentColor; /* Uses the text color */
  transition: width 0.3s ease-out;
}

.login-footer a:hover::after {
  width: 100%;
}

@media (max-width: 768px) {
  .login-container {
    padding: 20px;
  }
}
