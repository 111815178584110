.delivery-graphs-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.delivery-dashboard-title {
  font-family: "Figtree-SemiBold";
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 15px;
}
.delivery-dashboard-table-header {
  background-color: #f9f9f9;
  border-radius: 5px;
  padding: 10px;
}

.delivery-dashboard-table-header th {
  font-family: "Figtree-SemiBold";
  font-size: 0.9rem;
  color: #333;
  text-align: left;
}

.delivery-dashboard-table-body {
  background-color: #fff;
  border-radius: 5px;
  padding: 10px;
}

.delivery-dashboard-table-body tr {
  border-bottom: 1px solid #f0f0f0;
}

.delivery-dashboard-table-body td {
  font-family: "Figtree-Regular";
  font-size: 0.9rem;
  color: #333;
  text-align: left;
}

.delivery-pagination-box {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
}

.delivery-pagination-btn {
  background-color: #735dff26;
  color: #735dff;
  border: none;
  padding: 5px 15px;
  cursor: pointer;
  margin: 0 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.delivery-pagination-btn:hover {
  background-color: #735dff;
  color: #fff;
}

.delivery-pagination-btn:disabled {
  background-color: #e0e0e0;
  color: #999;
}

.delivery-page-number {
  font-size: 0.9rem;
  margin: 0 10px;
}
