.table {
  width: 80%;
  border-collapse: collapse;
  margin: auto;
}

.table th,
.table td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.table th {
  background-color: #f2f2f2;
}
.btn-edit {
  color: green;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.btn-delete {
  color: red;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.input-search {
  width: auto;
  margin-right: 20px;
}
.loremimages img {
  max-height: 400px;
}
.loremimages {
  display: flex;
  text-align: center;

  justify-content: center;
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 20px;
  max-width: 90%;
  width: 60%;
  height: 100%;
}

.popup form {
  display: flex;
  flex-direction: column;
}

.popup .form-group {
  margin-bottom: 15px;
}

.popup label {
  font-weight: bold;
  margin-bottom: 5px;
}

.popup input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
}

.popup button[type="submit"] {
  padding: 8px 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.popup button[type="submit"]:hover {
  background-color: #0056b3;
}
.table a {
  text-decoration: none;
  color: black;
}
.mt-60 {
  margin-top: 60px;
}
.table-container {
  overflow: auto;
}
