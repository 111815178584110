.graphs-container {
  border-radius: 15px;
  margin-bottom: 20px;
}

.collection-dashboard-title {
  font-family: "Figtree-SemiBold";
  font-size: 1.2rem; /* Smaller font size for title */
  color: #333;
  margin-bottom: 15px;
}

.pie-title-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.dashboard-pie-title {
  color: #333;
  margin-right: 10px;
  font-family: "Figtree-SemiBold";
  font-size: 1rem;
}
.pie-title-box button {
  background-color: #735dff26;
  color: #735dff;
  border: none;
  padding: 5px 15px;
  margin: 0;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.pie-title-box button:not(:last-child) {
  border-right: 1px solid #735dff; /* Adds the divider between buttons */
}

.pie-title-box button:first-child {
  border-radius: 5px 0 0 5px; /* Rounded corners on the left */
}

.pie-title-box button:last-child {
  border-radius: 0 5px 5px 0; /* Rounded corners on the right */
}

.pie-title-box button:hover {
  background-color: #735dff;
  color: #fff;
}

.pie-title-box .active-months {
  background-color: #735dff;
  color: white;
  border: none;
}

.handout-delivery-pie-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}

.handout-pie-title {
  font-family: "Figtree-SemiBold";
  font-size: 1rem;
  color: #333;
}

.handout-pie-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.handout-pie-container {
  margin: 20px 0 0 0;
}

.delivery-pie-title {
  font-family: "Figtree-SemiBold";
  font-size: 1rem;
  color: #333;
}

.delivery-pie-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.delivery-pie-container {
  margin: 20px 0 0 0;
}
