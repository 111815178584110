.mt-100 {
  margin-top: 100px;
}
.form-container {
  padding: 1rem 3rem;
}
.form-field {
  margin: 0.25rem 0;
}
.card-body label {
  font-size: 14px;
}

.modal-scroll {
  height: 80vh;
  overflow-y: auto;
}
.save {
  background-color: gray;
}
.heading-color {
  color: #f97b22;
}
.modal-scroll::-webkit-scrollbar {
  width: 10px;
}
.modal-scroll::-webkit-scrollbar-thumb {
  background: #f97b22;
}
.mt-50 {
  margin-top: 50px;
}
.mt-60 {
  margin-top: 60px;
}
.page-number-box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 10px;
}
.mt-80 {
  margin-top: 80px;
}
.page-number-box .pagination {
  margin-bottom: 0;
}

.page-number-box .pagination li a {
  font-size: 0.85rem;
}

.page-number-box .form-select {
  font-size: 0.85rem;
  padding: 0.25rem 0.5rem;
  max-width: 200px;
}
.flex-size {
  flex: 0.7;
}
