.reminder-rx-reminder-btn {
  border: 1px solid #735dff26;
  background-color: #f8f9fa;
  color: #735dff;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  outline: none;
  border-radius: 4px;
}

.reminder-rx-reminder-btn:hover {
  background-color: #735dff;
  color: #ffffff;
}
.reminder-rx-active-filter-btn {
  border: 1px solid #735dff26;
  background-color: #735dff;
  color: #ffffff;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  outline: none;
  border-radius: 4px;
}

.reminder-rx-active-filter-btn:hover {
  background-color: #ffffff;
  border-color: #735dff;
  border: 1px solid #735dff;
  color: #735dff;
}

/* Table container */
.reminder-rx-table-container {
  height: calc(100vh - 300px);
  margin-top: 20px;
  overflow: auto;
}

.reminder-rx-table-container table {
  width: 100%;
  font-family: "Figtree-Regular";
  border: 1px solid #ddd;
}

.reminder-rx-table-container th {
  background-color: #f9f9f9;
  font-family: "Figtree-SemiBold";
  font-size: 0.9rem;
  color: #333;
  text-align: left;
  padding: 10px;
}

.reminder-rx-table-container td {
  padding: 10px;
  font-family: "Figtree-Regular";
  font-size: 0.9rem;
  color: #333;
  border-bottom: 1px solid #f0f0f0;
}

/* Pagination */
.reminder-rx-pagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.reminder-rx-pagination-btn {
  background-color: #735dff26;
  color: #735dff;
  border: none;
  padding: 5px 15px;
  cursor: pointer;
  margin: 0 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
  font-family: "Figtree-SemiBold";
}

.reminder-rx-pagination-btn:hover {
  background-color: #735dff;
  color: #fff;
}

.reminder-rx-pagination-btn:disabled {
  background-color: #e0e0e0;
  color: #999;
}

.reminder-rx-page-number {
  font-size: 0.9rem;
  font-family: "Figtree-Regular";
  color: #666;
}
/* Per page */
.reminder-rx-items-per-page-selector {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 10px 0;
}

.reminder-rx-form-select {
  border: 1px solid #5933cc !important;
  background-color: #f8f9fa !important;
  color: #5933cc !important;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  outline: none;
  border-radius: 4px;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  height: fit-content;
  padding: 2px 5px;
  line-height: normal;
  height: auto;
}

.reminder-rx-items-per-page-option {
  font-size: 0.9rem;
  padding: 5px 10px;
  margin: 0 5px;
}
/* Main container */
.reminder-rx-container {
  position: relative;
  padding: 1rem 1.2rem;
  margin-top: 60px;
  height: calc(100vh - 60px);
}

/* Header container for Create button and Legends */
.reminder-rx-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}
.reminder-rx-date-filter-box {
  display: flex;
  flex-direction: row;
}
.reminder-rx-date-filter-text {
  margin: 0 5px;
  display: flex;
  align-items: center;
}
.reminder-rx-date-input {
  border: 1px solid #735dff26;
  background-color: #f8f9fa;
  color: #000;
  font-family: "Figtree-regular";
  font-size: 0.85rem;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  outline: none;
  border-radius: 4px;
}
.reminder-rx-date-filter-button {
  border: 1px solid #735dff26;
  background-color: #f8f9fa;
  color: #735dff;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  outline: none;
  border-radius: 4px;
  margin-left: 20px;
}
.reminder-rx-date-filter-button:hover {
  background-color: #735dff;
  color: #ffffff;
}
.reminder-rx-search-input-box {
  width: 100%;
  margin-bottom: 10px;
}
.reminder-rx-search-input {
  width: 100%;
}

/* Offcanvas styles */
.reminder-rx-offcanvas {
  width: 75% !important;
}

.reminder-rx-offcanvas-header {
  background-color: #f9f9f9;
  border-bottom: 1px solid #ddd;
  padding: 1rem 1.5rem;
}

.reminder-rx-offcanvas-header .btn-close {
  margin: 0;
}

.reminder-rx-offcanvas-body {
  padding: 1.5rem;
}

.reminder-rx-offcanvas-form {
  width: 100%;
}

.reminder-rx-offcanvas-footer {
  padding: 1rem 1.5rem;
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  border-top: 1px solid #ddd;
  background-color: #f9f9f9;
}

.reminder-rx-offcanvas-title {
  font-family: "Figtree-SemiBold";
  color: #333;
  margin-bottom: 1.5rem;
  padding: 0 0.75rem;
}

/* Form styles within offcanvas */
.reminder-rx-offcanvas .form-group {
  margin-bottom: 1.5rem;
}

.reminder-rx-offcanvas .form-label {
  font-family: "Figtree-SemiBold";
  margin-bottom: 0.5rem;
}

.reminder-rx-offcanvas .form-control {
  font-family: "Figtree-Regular";
  border-radius: 5px;
  border: 1px solid #ddd;
  padding: 0.5rem;
}

/* Detail view styles */
.reminder-rx-details-section {
  margin-bottom: 2rem;
}

.reminder-rx-detail-item {
  margin-bottom: 1rem;
  font-family: "Figtree-Regular";
  padding: 0.5rem;
  border-bottom: 1px solid #f0f0f0;
}

.reminder-rx-detail-item strong {
  font-family: "Figtree-SemiBold";
  margin-right: 0.5rem;
  color: #666;
}

/* Delete Modal specific styles */
.reminder-rx-delete-modal .modal-content {
  border-radius: 8px;
}

.reminder-rx-delete-modal .modal-header {
  background-color: #f8d7da;
  border-bottom: 1px solid #f5c6cb;
}

.reminder-rx-delete-modal .modal-title {
  color: #721c24;
  font-family: "Figtree-SemiBold";
}

.reminder-rx-delete-modal .modal-body {
  padding: 1.5rem;
  font-family: "Figtree-Regular";
}

/* Confirmation Modal specific styles */
.reminder-rx-confirmation-modal .modal-content {
  border-radius: 8px;
}

.reminder-rx-confirmation-modal .modal-header {
  background-color: #f8d7da;
  border-bottom: 1px solid #f5c6cb;
}

.reminder-rx-confirmation-modal .modal-title {
  color: #721c24;
  font-family: "Figtree-SemiBold";
}

.reminder-rx-confirmation-modal .modal-body {
  padding: 1.5rem;
  font-family: "Figtree-Regular";
}

/* Button styles */
.reminder-rx-confirmation-modal .btn-primary {
  background-color: #735dff;
  border-color: #735dff;
}

.reminder-rx-confirmation-modal .btn-primary:hover {
  background-color: #5933cc;
  border-color: #5933cc;
}
