.quote {
  font-size: 16px;
  text-align: center;
  margin-top: 20px;
  opacity: 0;
  animation: fadeIn 3s ease-in-out infinite;
  color: #fff;
  text-shadow: 1px 2px 3px darkslategray;
  font-size: 1.5rem;
}

@keyframes fadeIn {
  0%,
  100% {
    opacity: 0;
  }
  25%,
  75% {
    opacity: 1;
  }
}
