.sidebar-main {
  position: absolute; /* Position the sidebar absolutely */
  top: 0;
  left: 0; /* Adjust as per the layout */
  height: 100%; /* Adjust as per the layout */
  overflow-x: hidden; /* Hide horizontal overflow */
  transition: width 0.3s ease;
  z-index: 1000; /* Ensure it's above other content */
}
.sidebar-visible {
  width: 200px;
  /* Other styles for visible state */
}
.brand-logo {
  color: white;
}
.dropdown-item a {
  color: black !important;
}
.sidebar-ul {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px;
}
.sidebar-ul li {
  list-style: none;
  margin: 0.2rem 0;
  padding: 0 0.7rem;
}
.sidebar-ul a {
  text-decoration: none;
}
.sidebar-ul i {
  margin-right: 15px;
}
.sidebar-div {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  cursor: pointer;
}
.med-png {
  width: 230px;
}
.sidebar-logo {
  margin-left: 10px;
  max-width: 85%;
  height: auto;
}

.menu-icon-class {
  display: flex;
  width: 100%;
  justify-content: end;
  align-items: end;
  margin-top: 5px;
  margin-left: -10px;
}
.admin-nav {
  margin-right: 2px;
}
.admin-nav span {
  font-size: 12px;
  margin-left: 5px;
}
.nav-link-icon {
  font-size: 16px;
  margin-right: 5px;
} /* Sidebar base styles */
.sidebar {
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
  transition: width 0.3s ease;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border: 0px;
}
.nav-item {
  color: rgb(0, 0, 0);
}
.sidebar a,
.sidebar .admin-nav {
  display: flex;
  align-items: center;
}
.sidebar .admin-nav {
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
  margin: 0.3rem 0;
}
.nav-item:hover,
.admin-nav:hover,
.sidebar-ul a:hover {
  color: #07bfb5;
}
.nav-item-active {
  font-weight: bold;
  color: #07bfb5;
}
/* Main content adjustment */
.main-content {
  transition: margin-left 0.3s ease;
  margin-left: 60px; /* Initial state matching sidebar initial width */
}

.nav-link-icon {
  margin-right: 10px;
}
.admin-nav span {
  display: flex;
  flex-wrap: nowrap;
}
