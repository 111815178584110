.profile-menu-btn {
  position: absolute;
  background-color: #fff;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  right: 0;
  min-width: 150px; /* Increased the min-width */
  z-index: 1000; /* Ensure dropdown is on top */
  padding: 10px 0 0 0;
  border-radius: 8px; /* Reduced radius for a subtle curve */
  border: 1px solid #ccc; /* Light border to enhance visibility */
}
.profile-menu-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 10px;
  border-bottom: 0.1px solid #ccc;
}
.profile-menu-name {
  font-size: 1rem;
  font-family: "Figtree-SemiBold";
  text-transform: capitalize;
  margin: 0;
}
.profile-menu-role {
  font-size: 0.75rem;
  margin: 0;
  padding: 2px 10px;
  color: #ff5a29;
  font-family: "Figtree-Regular";
}
.profile-menu-btn > button > a {
  color: #333;
  text-decoration: none;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
}
.profile-dropdown-btn {
  border: none;
  width: 100%;
  text-align: left;
  background: none !important;
  padding: 10px 15px; /* Increased padding for better spacing */
  color: #333; /* Darker text for better readability */
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover */
}

.profile-dropdown-btn:hover,
.profile-dropdown-btn > a:hover {
  color: #735dff !important; /* Highlight color for text on hover */
}

.header-profile-btn {
  border-radius: 50%;
  border: none;
  cursor: pointer; /* Pointer cursor on hover */
  display: flex;
  align-items: center;
  position: relative;
  top: -1px;
}

.profile-picture-icon {
  font-size: 1.5rem;
  border-radius: 50%;
  background-color: #ffcb00;
}

.btn-svg {
  margin-right: 5px; /* Space between icon and text */
  font-size: 1.2rem; /* Larger icons */
}

.dropdown-link {
  text-decoration: none;
  color: inherit; /* Inherit color from parent */
  width: 100%; /* Ensure link uses full width for easier clicking */
  font-family: "Figtree-Regular";
}
.logout-btn {
  background-color: #735dff;
  border-radius: 5px;
  padding: 5px 15px;
  border: none;
  color: #fff;
  font-size: 0.9rem;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.logout-btn:hover {
  background-color: #fff;
  color: #735dff;
  border: 1px solid #735dff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}
