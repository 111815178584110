/* adminDashboardBox.css */
.dashboardMainBox {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 20px 0;
}
.management-dashboard-title {
  font-family: "Figtree-SemiBold";
  font-size: 1.2rem; /* Smaller font size for title */
  color: #333;
  margin-bottom: 15px;
}

.dashSubBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 20px;
  border-radius: 10px;
  margin: 10px;
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  min-width: 30px;
  justify-content: space-between;
}

.dashIconMainBox {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.dashIconContainer {
  padding: 6px;
  border-radius: 50%; /* Circular background for the icon */
  display: flex;
  justify-content: center;
  align-items: flex-start;
  animation: pulseAnimation 2s infinite ease-out;
  margin-right: 15px; /* Space between icon and text */
}

.dashBoardNavLink {
  font-size: 0.8rem;
  margin: 10px 0 0 0;
  text-shadow: 1px 1px 1px ghostwhite;
  font-family: "Figtree-LightItalic";
}
.dashInfoBox {
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: center;
}

.dashCount {
  font-size: 1.2rem;
  margin: 10px 0 0 0;
  font-family: "Figtree-SemiBold";
}

.dashTitle {
  font-size: 1rem;
  color: #333;
  margin: 0;
  text-shadow: 1px 1px 1px ghostwhite;
  font-family: "Figtree-Regular";
}

.dashLink {
  text-decoration: none;
  color: #333;
}

.dashNavLink {
  font-size: 1rem;
  color: #333;
  margin: 0;
  text-shadow: 1px 1px 1px ghostwhite;
}

@keyframes pulseAnimation {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
  }
  70% {
    transform: scale(1.05);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
@media screen and (max-width: 1000px) {
  .dashboardMainBox {
    justify-content: center;
  }
}
@media screen and (max-width: 500px) {
  .dashSubBox {
    min-width: 100%;
  }
}
