.form-check-input:focus {
  box-shadow: none;
}
.form-control:focus {
  color: #212529;
  outline: 0;
  box-shadow: none;
}
.form-container {
  padding: 1rem 3rem;
}
.mt-60 {
  margin-top: 60px;
}
.table-container {
  overflow: auto;
}
