.page-container {
  padding: 1rem 1.5rem;
}

.collection-form-container {
  margin: 0rem 0rem;
}

.form-field {
  padding: 0.25rem 1rem;
}
.cancel-wrapper {
  display: flex;
  justify-content: end;
}
